import { memo } from 'react';
import Stack from '@mui/material/Stack';
import { NavSectionProps } from '../types';
import { navVerticalConfig } from '../config';
import Group from './group';

function NavSectionVertical({ data, config, sx, ...other }: NavSectionProps) {
  return (
    <Stack sx={sx} {...other}>
      {data.map((group, index) => (
        <Group
          key={group.subheader || index}
          items={group.items}
          config={navVerticalConfig(config)}
        />
      ))}
    </Stack>
  );
}

export default memo(NavSectionVertical);

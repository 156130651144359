/* eslint-disable no-nested-ternary */
import { m } from 'framer-motion';
import { alpha } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useRouter } from 'src/routes/hook';
import { useAuthContext } from 'src/auth/hooks';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { HOST_API } from 'src/config-global';
import { API_ENDPOINTS } from 'src/utils/axios';

const OPTIONSNOSUBS = [
  {
    label: 'General',
    linkTo: '/profile/general',
  },
  {
    label: 'Billing',
    linkTo: '/profile/billing',
  },
  {
    label: 'Notifications',
    linkTo: '/profile/notifications',
  },
];

const OPTIONSComp = [
  {
    label: 'Strategies',
    linkTo: '/strategies',
  },
  {
    label: 'General',
    linkTo: '/profile/general',
  },
  {
    label: 'Channels',
    linkTo: '/profile/channels',
  },
  {
    label: 'Billing',
    linkTo: '/profile/billing',
  },
  {
    label: 'Notifications',
    linkTo: '/profile/notifications',
  },
];

const OPTIONSInd = [
  {
    label: 'Content',
    linkTo: '/strategies/content',
  },
  {
    label: 'General',
    linkTo: '/profile/general',
  },
  {
    label: 'Channels',
    linkTo: '/profile/channels',
  },
  {
    label: 'Billing',
    linkTo: '/profile/billing',
  },
  {
    label: 'Notifications',
    linkTo: '/profile/notifications',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const { user } = useAuthContext();

  const { logout } = useAuthContext();

  const popover = usePopover();

  const isMobileScreen = useMediaQuery('(max-width:1200px)');

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickItem = (path: string) => {
    popover.onClose();
    router.push(path);
  };

  const handleClose = () => {
    popover.onClose();
  };

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={
          !isMobileScreen
            ? {
                width: 40,
                height: 40,
                background: (theme) => alpha(theme.palette.grey[500], 0.08),
                ...(popover.open && {
                  background: (theme) =>
                    `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                }),
              }
            : { padding: '0' }
        }
      >
        {!isMobileScreen && (
          <Avatar
            sx={{
              width: 48,
              height: 48,
              border: (theme) => `solid 2px ${theme.palette.background.default}`,
              background: '#DFE3E8',
              color: 'action.active',
              fontSize: '24px',
            }}
            alt={user?.username}
            src={
              user?.avatar
                ? `${HOST_API}${API_ENDPOINTS.posts.displayImage}${user.avatar}?userID=${user?._id}`
                : ''
            }
          >
            {user?.username[0]}
          </Avatar>
        )}
        {isMobileScreen && (
          <Box
            component="img"
            src="/assets/header/burger.png"
            sx={{ width: '40px', height: '40px' }}
          />
        )}
      </IconButton>

      <CustomPopover
        anchorPosition={{ top: 0, left: 0 }}
        open={popover.open}
        onClose={popover.onClose}
        sx={
          isMobileScreen
            ? {
                width: '100vw',
                height: '100vh',
                maxWidth: '100%',
                maxHeight: '100%',
                marginLeft: '-16px',
                marginTop: '16px',
                backgroundColor: 'white',
                padding: '12px 16px',
                display: 'flex',
                flexDirection: 'column',
              }
            : { width: 200, p: 0 }
        }
      >
        {isMobileScreen && (
          <Box onClick={handleClose} order="1" width="40px" height="40px">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.8787 19.2927C18.2692 19.6833 18.2692 20.3164 17.8787 20.707L12.9291 25.6566C12.5386 26.0471 12.5386 26.6802 12.9291 27.0708C13.3196 27.4613 13.9528 27.4613 14.3433 27.0708L19.2929 22.1212C19.6835 21.7306 20.3166 21.7306 20.7071 22.1212L25.657 27.0711C26.0476 27.4616 26.6807 27.4616 27.0713 27.0711C27.4618 26.6805 27.4618 26.0474 27.0713 25.6569L22.1214 20.707C21.7308 20.3164 21.7308 19.6833 22.1214 19.2927L27.0713 14.3428C27.4618 13.9523 27.4618 13.3192 27.0713 12.9286C26.6807 12.5381 26.0476 12.5381 25.657 12.9286L20.7072 17.8785C20.3166 18.2691 19.6835 18.2691 19.2929 17.8785L14.3433 12.9289C13.9528 12.5384 13.3196 12.5384 12.9291 12.9289C12.5386 13.3195 12.5386 13.9526 12.9291 14.3431L17.8787 19.2927Z"
                fill="black"
              />
            </svg>
          </Box>
        )}
        <Box sx={{ display: 'flex', gap: '16', alignItems: 'center', order: '3' }}>
          {isMobileScreen && (
            <Avatar
              sx={{
                width: 48,
                height: 48,
                border: (theme) => `solid 2px ${theme.palette.background.default}`,
                background: '#DFE3E8',
                color: 'action.active',
                fontSize: '24px',
              }}
              alt={user?.username}
              src={
                user?.avatar
                  ? `${HOST_API}${API_ENDPOINTS.posts.displayImage}${user.avatar}?userID=${user?._id}`
                  : ''
              }
            >
              {user?.username[0]}
            </Avatar>
          )}
          <Box sx={{ p: 2, pb: 1.5 }}>
            <Typography variant="subtitle2" noWrap>
              {user?.username}
            </Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {user?.email}
            </Typography>
          </Box>
        </Box>

        {!isMobileScreen && <Divider sx={{ borderStyle: 'dashed' }} />}

        <Stack
          sx={
            isMobileScreen
              ? {
                  order: '2',
                  width: '100%',
                  flexGrow: '1',
                  display: 'flex',
                  justifyContent: 'center',
                }
              : { p: 1 }
          }
        >
          {(user?.subscriptions.smm.priceID !== 2 && user?.subscriptions.leadgen.priceID !== 2
            ? OPTIONSNOSUBS
            : user?.subscriptions.smm.typeSubs === 'ind'
            ? OPTIONSInd
            : OPTIONSComp
          ).map((option) => (
            <MenuItem
              key={option.label}
              sx={isMobileScreen ? { fontSize: '24px', fontWeight: '600' } : null}
              onClick={() => handleClickItem(option.linkTo)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        {!isMobileScreen && <Divider sx={{ borderStyle: 'dashed' }} />}

        <MenuItem
          onClick={handleLogout}
          sx={{
            minHeight: '36px',
            backgroundColor: isMobileScreen ? 'rgba(255, 86, 48, 0.08)' : 'inherit',
            fontWeight: 'fontWeightBold',
            color: '#B71D18',
            order: '4',
            width: isMobileScreen ? '100%' : 'auto',
            display: 'flex',
            justifyContent: isMobileScreen ? 'center' : 'flex-start',
            alignItems: 'center',
            m: isMobileScreen ? '0' : 1,
          }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
    </>
  );
}

/* eslint-disable no-nested-ternary */
import Box, { BoxProps } from '@mui/material/Box';
import { useResponsive } from 'src/hooks/use-responsive';
import { useSettingsContext } from 'src/components/settings';
import { useMediaQuery } from '@mui/material';
import { useAuthContext } from 'src/auth/hooks';
import { HEADER, NAV } from '../config-layout';

export default function Main({ children, sx, ...other }: BoxProps) {
  const settings = useSettingsContext();

  const isTabScreen = useMediaQuery('(max-width:1200px)');

  const lgUp = useResponsive('up', 'lg');

  const pathName = window.location.pathname;

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const { user } = useAuthContext();

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          pb: 10,
          ...(lgUp && {
            pt: `${HEADER.H_MOBILE * 2 + 40}px`,
            pb: 15,
          }),
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: pathName.includes('set-up-lead-gen-camp') ? '12px' : isTabScreen ? '76px' : '12px',
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI}px)`,
          }),
        }),
        backgroundColor:
          pathName.includes('content') && user?.subscriptions.smm.typeSubs === 'ind'
            ? 'rgba(247, 247, 248, 1)'
            : 'unset',
        ...sx,
      }}
      {...other}
    >
      {children}
    </Box>
  );
}

import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';

const CreateCampaignPage = lazy(() => import('src/pages/create-campaign/view'));
const CreateAccount = lazy(() => import('src/pages/connect-channel/connect-channel'));

export const createCampRoutes = [
  {
    path: 'set-up-lead-gen-camp',
    element: (
      <AuthGuard>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </AuthGuard>
    ),
    children: [
      { element: <CreateCampaignPage />, index: true },
      { path: 'connect-channel', element: <CreateAccount /> },
    ],
  },
];

import { useEffect } from 'react';
import { useAuthContext } from 'src/auth/hooks';
import { useNavigate } from 'react-router-dom';
import { paths } from 'src/routes/paths';

type Props = {
  children: React.ReactNode;
};

export default function Guard({ children }: Props) {
  const { user } = useAuthContext();

  const navigate = useNavigate();

  const currentPath = window.location.pathname;

  useEffect(() => {
    if (user?.subscriptions.smm.priceID !== 2 && user?.subscriptions.leadgen.priceID !== 2) {
      navigate(paths.options.root);
    }
    if (user?.subscriptions.smm.typeSubs === 'ind' && currentPath !== '/strategies/content') {
      navigate(
        user?.firstLogin === 1
          ? paths.strategies.content
          : `${paths.strategies.content}?tab=overview`
      );
    }
  }, [currentPath, navigate, user?.firstLogin, user?.subscriptions]);
  return <>{children}</>;
}

/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Typography, Tabs, Tab, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { useBoolean } from 'src/hooks/use-boolean';
import { useSettingsContext } from 'src/components/settings';
import { paths } from 'src/routes/paths';
import { useAuthContext } from 'src/auth/hooks';
import Main from './main';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import Header from './header';

type Props = {
  children: React.ReactNode;
};

export default function ProfileLayout({ children }: Props) {
  const currentPath = window.location.pathname;

  useEffect(() => {
    if (currentPath === paths.profile.general) {
      setCurrentTabValue('General');
    } else if (currentPath === paths.profile.channels) {
      setCurrentTabValue('Channels');
    } else if (currentPath === paths.profile.notifications) {
      setCurrentTabValue('Notifications');
    } else if (currentPath === paths.profile.billing) {
      setCurrentTabValue('Billing');
    }
  }, [currentPath]);

  const settings = useSettingsContext();

  const { user } = useAuthContext();

  const nav = useBoolean();

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  const [currentTabValue, setCurrentTabValue] = useState<string>('General');

  const isTabScreen = useMediaQuery('(max-width:1200px)');

  const handleTabChange = (e: React.SyntheticEvent, tabIndex: 'string') => {
    setCurrentTabValue(tabIndex);
  };

  const renderTabs = () => (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', maxWidth: { xs: '90vw', sm: 'unset' } }}>
      <Tabs
        value={currentTabValue}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        <Tab label="General" value="General" component={Link} to={paths.profile.general} />
        <Tab label="Channels" value="Channels" component={Link} to={paths.profile.channels} />
        <Tab label="Billing" value="Billing" component={Link} to={paths.profile.billing} />
        <Tab
          label="Notifications"
          value="Notifications"
          component={Link}
          to={paths.profile.notifications}
        />
      </Tabs>
    </Box>
  );

  return (
    <>
      {user?.subscriptions.smm.priceID !== 2 && user?.subscriptions.leadgen.priceID !== 2 && (
        <Header onOpenNav={nav.onTrue} />
      )}
      <Box
        sx={{
          display: 'flex',
          minHeight: 1,
        }}
      >
        {(user?.subscriptions.smm.priceID === 2 || user?.subscriptions.leadgen.priceID === 2) &&
          (isTabScreen ? (
            <Header onOpenNav={nav.onTrue} />
          ) : isMini ? (
            renderNavMini
          ) : (
            renderNavVertical
          ))}
        <Main>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '40px',
              position: 'relative',
              px: '24px',
            }}
          >
            <Typography variant="h2" alignSelf="flex-start" sx={{ mb: '-28px', px: '0' }}>
              Profile
            </Typography>
            {renderTabs()}

            {children}
          </Box>
        </Main>
      </Box>
    </>
  );
}

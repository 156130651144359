// import { useCallback, useEffect } from 'react';
// routes
// import { paths } from 'src/routes/paths';
// import { useRouter } from 'src/routes/hook';
//
import { Helmet } from 'react-helmet-async';
// import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: React.ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  // const router = useRouter();

  // const { authenticated } = useAuthContext();

  const script = `var APP_ID = "gycf30f9";
        
  window.intercomSettings = {
  app_id: APP_ID,
  };
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

  // const check = useCallback(() => {
  //   if (authenticated) {
  //     router.replace(paths.strategies.root);
  //   }
  // }, [authenticated, router]);

  // useEffect(() => {
  //   check();
  // }, [check]);

  return (
    <>
      <Helmet>
        <title> Subscription options page</title>
        <script>{script}</script>
      </Helmet>
      {children}
    </>
  );
}

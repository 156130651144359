import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  user: icon('ic_user'),
  strategies: icon('ic_strategies'),
  channels: icon('ic_channels'),
  billing: icon('ic_billing'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { strategiesContext, user } = useAuthContext();

  const children = useMemo(() => {
    if (!strategiesContext) return [];

    return strategiesContext.map((strategy) => ({
      title: strategy.name,
      path: `${paths.strategies.content}?strategyId=${strategy.projectID}`,
      id: strategy.projectID,
      processing: !!strategy.processing,
    }));
  }, [strategiesContext]);

  const data = useMemo(
    () => [
      {
        items: [
          user?.subscriptions.smm.typeSubs === 'ind'
            ? {
                title: 'Content',
                path: paths.strategies.content,
                icon: ICONS.strategies,
              }
            : {
                title: 'Strategies',
                path: paths.strategies.root,
                icon: ICONS.strategies,
                children,
              },
        ],
      },
    ],
    [children, user?.subscriptions]
  );

  return data;
}

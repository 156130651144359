import Box from '@mui/material/Box';
import { useBoolean } from 'src/hooks/use-boolean';
import Main from './main';
import Header from './header';

type Props = {
  children: React.ReactNode;
};

export default function OptionsLayout({ children }: Props) {
  const nav = useBoolean();

  return (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        <Main>{children}</Main>
      </Box>
    </>
  );
}

import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Scrollbar from 'src/components/scrollbar';
import { usePathname, useRouter } from 'src/routes/hook';
import { NavSectionVertical } from 'src/components/nav-section';
import { useAuthContext } from 'src/auth/hooks';
import { useSettingsContext } from 'src/components/settings';
import NewLogo from 'src/components/logo/new-logo';
import { LoadingButton } from '@mui/lab';
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import AccountPopoverVertical from './account-popover-vertical';
import { useGetStrategies } from './hooks/useGetStrategies';
import SharingButton from '../_common/sharing-button';

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const { logout } = useAuthContext();

  const settings = useSettingsContext();

  const { getData } = useGetStrategies();

  const mini = settings.themeLayout === 'mini';

  const pathname = usePathname();

  const navData = useNavData();

  const currentPath = window.location.pathname;

  const router = useRouter();

  const handleLogout = async () => {
    try {
      await logout();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (currentPath !== '/strategies') {
      getData();
    }
  }, [currentPath, getData]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: mini ? 'center' : 'start',
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: mini ? 'center' : 'start',
        },
      }}
    >
      <NewLogo />

      <NavSectionVertical data={navData} sx={{ width: '100%', paddingTop: '8px' }} />

      <Box sx={{ flexGrow: 1 }} />

      <Box
        sx={{
          padding: '15px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          width: '100%',
        }}
      >
        <SharingButton />
        <AccountPopoverVertical />
        <Box width="100%">
          <LoadingButton onClick={handleLogout} sx={{ width: '100%', color: 'error.dark' }}>
            <span>Log out</span>
          </LoadingButton>
        </Box>
      </Box>

      {/* <NavUpgrade /> */}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: 0,
        width: NAV.W_VERTICAL,
      }}
    >
      {/* <NavToggleButton calcWidth={sideBarWidth}/> */}

      <Stack
        sx={{
          height: 1,
          position: 'fixed',
          width: NAV.W_VERTICAL,
          borderRight: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        {renderContent}
      </Stack>
    </Box>
  );
}

import { useCallback } from 'react';
import { getStrategies } from 'src/utils/api';
import { useTasks } from 'src/hooks/useTasks';
import { processingStrategies } from 'src/sections/marketing-strategies/functions/processingStrategies';
import { useAuthContext } from 'src/auth/hooks';
import { CustomError, Strategies, Task } from 'src/sections/types';

export const useGetStrategies = (
  updateState?: (newData: any) => void,
  errorHandler?: (e: CustomError | string) => void,
  finallyFunc?: () => void
) => {
  const { setStrategiesContext, strategiesContext } = useAuthContext();

  const processStrategies = useCallback(
    (strategies: Strategies, tasks: Task[]) => {
      const { updatedStrategies } = processingStrategies(strategies, tasks);
      setStrategiesContext(updatedStrategies);
      if (updateState) {
        updateState(updatedStrategies);
      }
    },
    [setStrategiesContext, updateState]
  );

  const getData = useCallback(async () => {
    try {
      const res = await getStrategies();
      setTasks(res.data.tasks);
      processStrategies(res.data.data, res.data.tasks);
    } catch (e) {
      console.log(e);
      if (errorHandler) {
        errorHandler(e);
      }
    } finally {
      if (finallyFunc) {
        finallyFunc();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setStrategiesContext]);

  const handleErrors = useCallback(
    (updatedTasks: Task[]) => {
      processStrategies(strategiesContext, updatedTasks);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [processStrategies, strategiesContext]
  );

  const { setTasks } = useTasks(getData, handleErrors);

  return { getData, processStrategies };
};

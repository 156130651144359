import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';

// ----------------------------------------------------------------------

const EmailConfirmationPage = lazy(() => import('src/pages/auth/jwt/email-confirmation'));
const ChangePasswordPage = lazy(() => import('src/pages/auth/change-password'));
const SuccessChangePswdPage = lazy(() => import('src/pages/auth/success-change-pswd'));
const LoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const RegisterPage = lazy(() => import('src/pages/auth/jwt/register'));
const ForgotPasswordPage = lazy(() => import('src/pages/auth/jwt/forgot-password'));

// ----------------------------------------------------------------------

const authJwt = {
  path: '',
  element: (
    <GuestGuard>
      <Outlet />
    </GuestGuard>
  ),
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <LoginPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'register',
      element: (
        <AuthClassicLayout title="Accelerate your marketing journey">
          <RegisterPage />
        </AuthClassicLayout>
      ),
    },
    {
      path: 'email-confirmation',
      element: <EmailConfirmationPage />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPasswordPage />,
    },
  ],
};

export const authRoutes = [
  {
    path: 'auth',
    children: [
      authJwt,
      {
        path: 'reset-password',
        element: <ChangePasswordPage />,
      },
      {
        path: 'success-change-pswd',
        element: <SuccessChangePswdPage />,
      },
    ],
  },
];

import { useEffect, useCallback, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter, useSearchParams } from 'src/routes/hook';
//
import { Helmet } from 'react-helmet-async';
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

const loginPaths: Record<string, string> = {
  jwt: paths.auth.login,
};

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const router = useRouter();

  const { authenticated, method, user } = useAuthContext();

  const searchParams2 = useSearchParams();

  const [checked, setChecked] = useState(false);

  const script = `var APP_ID = "gycf30f9";
        
window.intercomSettings = {
app_id: APP_ID,
email: "${user?.email}"
};
(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

  const [intercomScript, setIntercomScript] = useState<string | undefined>(undefined);

  const script2 = `window.dataLayer = window.dataLayer || [];
window.dataLayer.push({'user_id':${user?._id}});`;

  const [gaScript, setGaScript] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (user?.email) {
      setIntercomScript(script);
    }
    if (user?._id) {
      setGaScript(script2);
    }
  }, [script, script2, user?._id, user?.email]);

  const check = useCallback(() => {
    if (!authenticated) {
      const searchParams = new URLSearchParams({ returnTo: window.location.href }).toString();
      const ref = searchParams2.get('ref');

      const loginPath = loginPaths[method];

      const href = ref ? `${loginPath}?${searchParams}&ref=${ref}` : `${loginPath}?${searchParams}`;

      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [authenticated, method, router, searchParams2]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title> Subscription options page</title>
        {intercomScript && <script>{intercomScript}</script>}
        {gaScript && <script type="text/javascript">{gaScript}</script>}
      </Helmet>
      {children}
    </>
  );
}

import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const Onboarding = lazy(() => import('src/pages/onboarding/onboarding-page'));
const Content = lazy(() => import('src/pages/onboarding/content-page'));
const Sharing = lazy(() => import('src/pages/onboarding/sharing-page'));

// ----------------------------------------------------------------------

export const onboardingRoutes = [
  {
    path: 'onboarding',
    element: (
      <AuthGuard>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </AuthGuard>
    ),
    children: [
      { path: 'strategy', element: <Onboarding /> },
      { path: 'content', element: <Content /> },
      { path: 'sharing', element: <Sharing /> },
    ],
  },
];

import { Box } from '@mui/material';

export default function ArrowBox() {
  return (
    <Box
      sx={{
        borderRadius: '8px',
        border: '1px solid #EAEAEC',
        padding: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '24px',
        height: '24px',
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path d="M6 4L10 8L6 12" stroke="black" />
      </svg>
    </Box>
  );
}

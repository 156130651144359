import { Context } from './auth-context';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthConsumer({ children }: Props) {
  return <Context.Consumer>{(auth) => (auth.loading ? <> </> : children)}</Context.Consumer>;
}

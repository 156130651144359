import { Box, useMediaQuery } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useAuthContext } from 'src/auth/hooks';
import { HOST_API } from 'src/config-global';
import { API_ENDPOINTS } from 'src/utils/axios';
import { body3 } from 'src/theme/styles/text';
import { useNavigate } from 'react-router';
import { paths } from 'src/routes/paths';
import { useSettingsContext } from 'src/components/settings';
import { useState } from 'react';

import ArrowBox from './icons/arrow-box';

export default function AccountPopoverVertical() {
  const navigate = useNavigate();

  const { user } = useAuthContext();

  const settings = useSettingsContext();

  const [visible, setVisible] = useState(false);

  const mini = settings.themeLayout === 'mini';

  const isMobileScreen = useMediaQuery('(max-width:768px)');

  const handleNavigate = () => {
    setVisible(!visible);
    navigate(paths.profile.general);
  };

  return (
    <Box>
      <Box
        sx={{
          padding: mini ? '8px' : '8px 12px',
          border: '1px solid #EAEAEC',
          borderRadius: '16px',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '8px',
          cursor: 'pointer',
        }}
        onClick={handleNavigate}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {!isMobileScreen && (
            <Avatar
              sx={{
                width: mini ? '40px' : 48,
                height: mini ? '40px' : 48,
                background: '#FFDDF0',
                color: '#BB1E74',
                fontSize: '16px',
              }}
              alt={user?.username}
              src={
                user?.avatar
                  ? `${HOST_API}${API_ENDPOINTS.posts.displayImage}${user.avatar}?userID=${user?._id}`
                  : ''
              }
            >
              {`${user?.username[0]}${user?.username[1]}`}
            </Avatar>
          )}
          {isMobileScreen && (
            <Box
              component="img"
              src="/assets/header/burger.png"
              sx={{ width: '40px', height: '40px' }}
            />
          )}

          {!mini && (
            <Box>
              <Typography sx={{ ...body3, color: 'body.quaternary', letterSpacing: '-0.24px' }}>
                Profile
              </Typography>
              <Typography
                variant="body1"
                color="body.primary"
                letterSpacing="-0.32px"
                sx={{ width: '135px', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {user?.username}
              </Typography>
            </Box>
          )}
        </Box>
        {!mini && <ArrowBox />}
      </Box>
    </Box>
  );
}

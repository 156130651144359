import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import SEOLayout from 'src/layouts/dashboard/seo-layout';

const AuditReport = lazy(() => import('src/pages/seo/audit-report/seo'));

export const SEORoutes = [
  {
    path: 'seo-manager',
    element: (
      <AuthGuard>
        <SEOLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </SEOLayout>
      </AuthGuard>
    ),
    children: [{ path: 'audit-report', element: <AuditReport /> }],
  },
];

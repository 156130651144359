import Box from '@mui/material/Box';
import { useBoolean } from 'src/hooks/use-boolean';
import { useSettingsContext } from 'src/components/settings';
import { useMediaQuery } from '@mui/material';
import Main from './main';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';
import Header from './header';

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const settings = useSettingsContext();

  const nav = useBoolean();

  const isMini = settings.themeLayout === 'mini';

  const isTabScreen = useMediaQuery('(max-width:1200px)');

  const renderNavMini = <NavMini />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  if (isMini) {
    return (
      <Box
        sx={{
          display: 'flex',
          minHeight: 1,
        }}
      >
        {isTabScreen && <Header />}
        {!isTabScreen && renderNavMini}

        <Main>{children}</Main>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: 1,
      }}
    >
      {isTabScreen && <Header />}
      {!isTabScreen && renderNavVertical}
      <Main>{children}</Main>
    </Box>
  );
}

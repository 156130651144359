import { useEffect, useReducer, useCallback, useMemo, useState } from 'react';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { Strategies } from 'src/sections/types';
import { Context } from './auth-context';
import { isValidToken, setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType, UserData, Geolocation } from '../../types';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  UPDATE_USER = 'UPDATE_USER',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.UPDATE_USER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  if (action.type === Types.UPDATE_USER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const ACCESS_TOKEN_STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function ContextProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [strategiesContext, setStrategiesContext] = useState<Strategies>([]);

  const [tipStrategies, setTipStrategies] = useState(1);
  const [tipSegments, setTipSegments] = useState(1);
  const [tipCalendar, setTipCalendar] = useState(1);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = sessionStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
        const cashedUser = sessionStorage.getItem('currentUser');
        const cachedTipSegments = sessionStorage.getItem('tipSegments');
        const cachedTipStrategies = sessionStorage.getItem('tipStrategies');
        const cachedTipCalendar = sessionStorage.getItem('tipCalendar');

        if (accessToken && isValidToken(accessToken) && cashedUser != null) {
          const user = JSON.parse(cashedUser);
          setSession(accessToken, user);

          dispatch({
            type: Types.INITIAL,
            payload: {
              user,
            },
          });
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              user: null,
            },
          });
        }

        if (cachedTipSegments) {
          setTipSegments(Number(cachedTipSegments));
        }
        if (cachedTipStrategies) {
          setTipStrategies(Number(cachedTipStrategies));
        }
        if (cachedTipCalendar) {
          setTipCalendar(Number(cachedTipCalendar));
        }
      } catch (error) {
        console.error(error);
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const setUser = useCallback(
    (updatedUser: AuthUserType) => {
      dispatch({
        type: Types.UPDATE_USER,
        payload: {
          user: updatedUser,
        },
      });
    },
    [dispatch]
  );

  // LOGIN
  const login = useCallback(
    async (
      email: string,
      password: string,
      geolocation: Geolocation,
      ref?: string,
      tz?: number,
      tolt_referral?: string
    ): Promise<UserData> => {
      const data = {
        email,
        password,
        geolocation,
        ref,
        tz,
        tolt_referral,
      };

      const response = await axios.post<{ token: string; user: UserData }>(
        API_ENDPOINTS.auth.login,
        data
      );

      const { token, user } = response.data;

      setSession(token, user);

      dispatch({
        type: Types.LOGIN,
        payload: {
          user,
        },
      });
      return user;
    },
    []
  );

  // REGISTER
  const register = useCallback(
    async (
      email: string,
      password: string,
      firstname: string,
      lastname: string,
      ref?: string,
      via?: string,
      aff?: string,
      tolt_referral?: string
    ) => {
      const data = {
        email,
        password,
        firstname,
        lastname,
        system: 'mail',
        ref,
        via,
        aff,
        tolt_referral,
      };

      await axios.post(API_ENDPOINTS.auth.register, data);
    },
    []
  );

  // LOGIN WITH GOOGLE

  const loginGoogle = useCallback(
    async (
      token1: string,
      geolocation: Geolocation,
      ref?: string,
      via?: string,
      aff?: string,
      tz?: number,
      tolt_referral?: string
    ): Promise<UserData> => {
      const data = {
        geolocation,
        ref,
        via,
        aff,
        tz,
        tolt_referral,
      };
      const response = await axios.post<{ token: string; user: UserData }>(
        API_ENDPOINTS.auth.googleAuth,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: token1,
          },
        }
      );

      const { token, user } = response.data;

      setSession(token, user);

      dispatch({
        type: Types.LOGIN,
        payload: {
          user,
        },
      });
      return user;
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    sessionStorage.removeItem('strategyToOpen');
    sessionStorage.removeItem('returnTo');
    sessionStorage.removeItem('channel');
    sessionStorage.removeItem('chosen-subscription');
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // VERIFY EMAIL

  const verifyEmail = useCallback(async (token: string) => {
    const data = {
      token,
    };

    await axios.post(API_ENDPOINTS.auth.verifyMail, data);
  }, []);

  // RESEND EMAIL

  const resendEmail = useCallback(async (email: string) => {
    const data = {
      email,
    };

    await axios.post(API_ENDPOINTS.auth.resendMail, data);
  }, []);

  // SET PASSWORD

  const setPassword = useCallback(async (password: string, token: string) => {
    const data = {
      password,
      token,
    };

    await axios.post(API_ENDPOINTS.auth.setPassword, data);
  }, []);

  // RESET PASSWORD

  const resetPassword = useCallback(async (email: string) => {
    const data = {
      email,
    };

    await axios.post(API_ENDPOINTS.auth.resetPassword, data);
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      loginGoogle,
      register,
      logout,
      verifyEmail,
      resendEmail,
      setPassword,
      resetPassword,
      strategiesContext,
      setStrategiesContext,
      setUser,
      tipStrategies,
      setTipStrategies,
      tipSegments,
      setTipSegments,
      tipCalendar,
      setTipCalendar,
    }),
    [
      login,
      loginGoogle,
      logout,
      register,
      resendEmail,
      setPassword,
      state.user,
      status,
      verifyEmail,
      resetPassword,
      strategiesContext,
      setStrategiesContext,
      setUser,
      tipStrategies,
      setTipStrategies,
      tipSegments,
      setTipSegments,
      tipCalendar,
      setTipCalendar,
    ]
  );

  return <Context.Provider value={memoizedValue}>{children}</Context.Provider>;
}

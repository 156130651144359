import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';

const ChiefMO = lazy(() => import('src/pages/chief-mo/chief-mo'));

export const chiefMORoutes = [
  {
    path: 'chief-marketing-officer',
    element: (
      <AuthGuard>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </AuthGuard>
    ),
    children: [{ element: <ChiefMO />, index: true }],
  },
];

export const smallBold = {
  fontSize: '14px',
  fontWeight: '700',
  lineHeight: '22px',
};

export const bigBold = {
  fontSize: { xs: '28px', sm: '32px' },
  fontWeight: '700',
  lineHeight: { xs: '38px', sm: '48px' },
};

export const smallNormal = {
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '22px',
};

export const smallNormalShort = {
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '20px',
};

export const mediumBold = {
  fontSize: '16px',
  fontWeight: '700',
  lineHeight: '24px',
};

export const middleBoldHigh = {
  fontSize: '20px',
  fontWeight: '700',
  lineHeight: '28px',
};

export const smallBoldHigh = {
  fontSize: { xs: '14px', sm: '16px' },
  fontWeight: '700',
  lineHeight: { xs: '20px', sm: '28px' },
};

export const verySmall = {
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '18px',
};

export const overViewUpperCase = {
  fontSize: '14px',
  fontWeight: '600',
  lineHeight: '18px',
  textTransform: 'uppercase',
  color: 'text.primary',
};

export const label2 = {
  fontSize: '14px',
  fontWeight: '700',
  lineHeight: '20px',
};

export const label3 = {
  fontWeight: 700,
  lineHeight: '16px',
  fontSize: '12px',
};

export const body3 = {
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '16px',
  letterSpacing: '-0.24px',
};

export const subhead3 = {
  fontSize: '12px',
  fontWeight: '700',
  lineHeight: '16px',
  letterSpacing: '-0.24px',
};

const MAX_RETRY_ATTEMPTS = 3;

let retryCount: number = parseInt(sessionStorage.getItem('retryCount') || '0', 10);

let hasReloaded = false;

export function handleChunkErrorWithRetry(): void {
  window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
    if (event.reason && event.reason.name === 'ChunkLoadError') {
      if (hasReloaded) return;

      if (retryCount < MAX_RETRY_ATTEMPTS) {
        console.warn(`Chunk error. Page reload attempt ${retryCount + 1}/${MAX_RETRY_ATTEMPTS}`);
        retryCount += 1;
        sessionStorage.setItem('retryCount', retryCount.toString());

        hasReloaded = true;

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  });
}

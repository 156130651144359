import { List } from '@mui/material';
import { useSettingsContext } from 'src/components/settings';
import NavList from './nav-list';
import { NavConfigProps, NavListProps } from '../types';

type GroupProps = {
  items: NavListProps[];
  config: NavConfigProps;
};

export default function Group({ items, config }: GroupProps) {
  const settings = useSettingsContext();

  const mini = settings.themeLayout === 'mini';

  const renderContent = items.map((list, idx) => (
    <NavList
      key={list.title + list.path}
      data={list}
      depth={1}
      hasChild={!!list.children}
      config={config}
      index={idx}
    />
  ));

  return <List sx={{ px: mini ? 1 : 2 }}>{renderContent}</List>;
}

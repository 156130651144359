import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import OptionsLayout from 'src/layouts/dashboard/options-layout';

const PricingTable = lazy(() => import('src/pages/pricing/pricing-page'));
const PricingInfo = lazy(() => import('src/pages/pricing/pricing-info'));

export const pricingRoutes = [
  {
    path: 'pricing',
    element: (
      <AuthGuard>
        <OptionsLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </OptionsLayout>
      </AuthGuard>
    ),
    children: [
      { path: 'table', element: <PricingTable /> },
      { path: 'info', element: <PricingInfo /> },
    ],
  },
];

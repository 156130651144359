import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import { LoadingScreen } from 'src/components/loading-screen';
import OptionsLayout from 'src/layouts/dashboard/options-layout';

const OptionsPage = lazy(() => import('src/pages/subscription-options/subscription-options'));

export const optionsRoutes = [
  {
    path: 'subscription-options',
    element: (
      <AuthGuard>
        <OptionsLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </OptionsLayout>
      </AuthGuard>
    ),
    children: [{ element: <OptionsPage />, index: true }],
  },
];

import Box from '@mui/material/Box';
import Main from './main';

type Props = {
  children: React.ReactNode;
};

export default function SEOLayout({ children }: Props) {
  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <Main>{children}</Main>
    </Box>
  );
}

import { useSearchParams } from 'src/routes/hook';

// ----------------------------------------------------------------------

type ReturnType = boolean;

export function useActiveSubLink(id: number | undefined): ReturnType {
  const searchParams = useSearchParams();

  const strategyID = Number(searchParams.get('strategyId'));

  return strategyID === id;
}

import { useTheme } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { useResponsive } from 'src/hooks/use-responsive';
import { bgBlur } from 'src/theme/css';
import { useSettingsContext } from 'src/components/settings';
import { NAV } from '../config-layout';

interface NavToggleButtonProps extends IconButtonProps {
  calcWidth?: number;
}

export default function NavToggleButton({ calcWidth, sx, ...other }: NavToggleButtonProps) {
  const theme = useTheme();

  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const mini = settings.themeLayout === 'mini';

  if (!lgUp) {
    return null;
  }

  return (
    <IconButton
      onClick={() =>
        settings.onUpdate('themeLayout', settings.themeLayout === 'vertical' ? 'mini' : 'vertical')
      }
      sx={{
        p: '4px',
        // top: 32,
        position: mini ? 'fixed' : 'static',
        left: calcWidth ? calcWidth - 17 : NAV.W_VERTICAL - 17,
        zIndex: theme.zIndex.appBar + 1,
        border: `1px solid #EAEAEC`,
        borderRadius: '8px',
        ...bgBlur({ opacity: 0.48, color: theme.palette.background.default }),
        '&:hover': {
          bgcolor: 'background.default',
        },
        ...sx,
      }}
      {...other}
    >
      {mini ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 5H11V19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5ZM11 4H10H5C3.34315 4 2 5.34315 2 7V17C2 18.6569 3.34315 20 5 20H10H11H19C20.6569 20 22 18.6569 22 17V7C22 5.34315 20.6569 4 19 4H11ZM5 19H10V5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19ZM5.14645 10.3536C4.95118 10.1583 4.95118 9.84171 5.14645 9.64645C5.34171 9.45118 5.65829 9.45118 5.85355 9.64645L7.85355 11.6464C8.04882 11.8417 8.04882 12.1583 7.85355 12.3536L5.85355 14.3536C5.65829 14.5488 5.34171 14.5488 5.14645 14.3536C4.95118 14.1583 4.95118 13.8417 5.14645 13.6464L6.79289 12L5.14645 10.3536Z"
            fill="#726D78"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19 5H11V19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5ZM11 4H10H5C3.34315 4 2 5.34315 2 7V17C2 18.6569 3.34315 20 5 20H10H11H19C20.6569 20 22 18.6569 22 17V7C22 5.34315 20.6569 4 19 4H11ZM5 19H10V5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19ZM7.85355 10.3536C8.04882 10.1583 8.04882 9.84171 7.85355 9.64645C7.65829 9.45118 7.34171 9.45118 7.14645 9.64645L5.14645 11.6464C4.95118 11.8417 4.95118 12.1583 5.14645 12.3536L7.14645 14.3536C7.34171 14.5488 7.65829 14.5488 7.85355 14.3536C8.04882 14.1583 8.04882 13.8417 7.85355 13.6464L6.20711 12L7.85355 10.3536Z"
            fill="#726D78"
          />
        </svg>
      )}
    </IconButton>
  );
}

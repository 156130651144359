import { Box } from '@mui/material';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { Link } from 'react-router-dom';

type Props = {
  mini?: boolean;
};

export default function SharingButton(props: Props) {
  const { mini } = props;

  return (
    <Box
      component={Link}
      to="https://marketowl.tolt.io/"
      target="_blank"
      sx={{
        padding: '8px',
        borderRadius: '16px',
        border: (theme) => `1px solid ${theme.palette.divider}`,
        display: 'flex',
        gap: '16px',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        transition: '0.3s',
        color: 'body.primary',
        textDecoration: 'none',
        height: mini ? '58px' : 'auto',
        ':hover': {
          backgroundColor: (theme) => theme.palette.grey[200],
        },
      }}
    >
      <ShareOutlinedIcon sx={{ color: (theme) => theme.palette.grey[800] }} />
      {mini ? '' : `Refer`}
    </Box>
  );
}

import { useEffect, useState } from 'react';
import { CustomError, Segment, Task } from 'src/sections/types';
import { getTaskStatus } from 'src/utils/api/tasks';

export function useTasks(
  processData: (data?: Segment) => void,
  processErrors?: (updatedTasks: Task[]) => void,
  handleError?: (e: CustomError | string, id?: string) => void,
  segments?: boolean
) {
  const [tasks, setTasks] = useState<Task[]>([]);

  useEffect(() => {
    if (tasks.length === 0) return undefined;
    const intervalId = setInterval(async () => {
      tasks.forEach(async (task) => {
        try {
          const res = await getTaskStatus(task.task_id);
          if (res.data.status === 'completed') {
            processData(segments ? res.data.data : undefined);
            setTasks((prev) => prev.filter((t) => t.task_id !== task.task_id));
          } else if (res.data.status === 'failed') {
            if (processErrors) {
              processErrors(tasks.filter((t) => t.task_id !== task.task_id));
            }
            if (handleError) {
              handleError(res.data.msg, task.task_id);
            }
            setTasks((prev) => prev.filter((t) => t.task_id !== task.task_id));
          }
        } catch (e) {
          setTasks((prev) => prev.filter((t) => t.task_id !== task.task_id));
          if (processErrors) {
            processErrors(tasks.filter((t) => t.task_id !== task.task_id));
          }
          if (handleError) {
            handleError(e, task.task_id);
          }
        }
      });
    }, 15000);

    return () => clearInterval(intervalId);
  }, [handleError, processData, processErrors, segments, tasks]);

  return { setTasks, tasks };
}

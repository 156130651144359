import { Strategies, Task } from 'src/sections/types';

export function processingStrategies(strategies: Strategies, tasks: Task[]) {
  const strategiesTasks = tasks.filter((task) => task.obj_kind === 'strategy');
  const areProcessingStrategies =
    strategiesTasks.length > 0 &&
    strategiesTasks.some(
      (strategy) =>
        strategy.status !== 'success' &&
        strategy.status !== 'failure' &&
        strategy.status !== 'revoked'
    );
  const updatedStrategies = [...strategies];
  if (strategiesTasks.length > 0) {
    strategiesTasks.forEach((task) => {
      updatedStrategies.forEach((strategy) => {
        if (strategy.projectID === task.obj_id) {
          if (task.status !== 'success' && task.status !== 'failure' && task.status !== 'revoked') {
            strategy.processing = true;
          } else {
            delete strategy.processing;
          }
        }
      });
    });
  } else {
    updatedStrategies.forEach((strategy) => delete strategy.processing);
  }

  return { updatedStrategies, areProcessingStrategies };
}
